const PanelContainer = {
  baseStyle: {
    p: "0px 0px",
    minHeight: "100vh",
  },
};

export const PanelContainerComponent = {
  components: {
    PanelContainer,
  },
};
