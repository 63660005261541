import React, { Component, useEffect, useState, useRef } from 'react';
import ReactPlayer from "react-player";
import ReactAudioPlayer from 'react-audio-player';
import SoundOfVideo from './AudioOfVideo';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button
} from '@chakra-ui/react'

import useSound from 'use-sound';
import { isPlainObject } from 'lodash';
import phoneRing from '../../assets/sounds/ringing.mp3';

export default function SizeExample() {
    const player = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [play] = useSound(phoneRing);

    useEffect(() => {
        onOpen();
    }, []);
    
    useEffect(() => {
        if (!isOpen) {
            try {
                // SoundOfVideo(true, phoneRing);
                console.log('playing');
            } catch (error) {
                console.log(error);
            }
        }
    }, [isOpen]);

    return (
        <div className="player-wrapper">
            <Modal onClose={onClose} size="full" isOpen={isOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        {/* <ReactPlayer
                            ref={player}
                            className='react-player'
                            url={['video/Acclaim_Intro_Video.mp4']}
                            width="100%"
                            height="100%"
                            playing={true}
                            muted={true}
                            volume={1}
                            style={{ position: 'absolute', top: '0', left: '0' }}
                            onEnded={onClose}
                        /> */}
                        <iframe 
                            src={['video/video.html']} 
                            frameBorder="0" id="iframeResult" 
                            name="iframeResult" 
                            allow="autoplay; encrypted-media" 
                            style={{width: '100vw', height: '100vh', display: 'none'}}
                        />
                        <ReactPlayer
                            className='react-player'
                            url={['video/Acclaim_Intro_Video.mp4']}
                            width="100%"
                            height="100%"
                            onReady={() => {
                                console.log('playing');
                            }}
                            playing={isOpen}
                            muted
                            style={{ position: 'absolute', top: '0', left: '0' }}
                            onEnded={onClose}
                        />
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button colorScheme='blue' onClick={onClose}>Close</Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>
        </div>
    )
}